import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import age from "../../images/age.svg";

const Footer = () => {
  return (
    <footer id="contacts" className="winn-footer">
      <div className="winn-footer__el1">
        <img
          src={age}
          alt="age limit 18+"
          title="age limit 18+"
          width="54"
          height="54"
        />
        <Link to="/privacy" className="t-15 winn-footer__link col-white">
          Privacy Policy
        </Link>
      </div>
      <div className="winn-footer__text-container">
        <p className="t-15 col-white">
          winningaustralia.com it uses cookies that guarantee you maximum
          comfort. If you stay on the site, you agree to the use of your
          cookies.
        </p>
        <Link to="/cookies" className="t-15 winn-footer__link col-white">
          Learn more
        </Link>
      </div>
      <p className="t-15 col-white">
        Disclaimer: All information on this site is for informational purposes
        only. At the time of writing, the information has been taken from public
        sources. For up-to-date information, please contact our support team or
        write to the school you are interested in.
      </p>
    </footer>
  );
};

export default Footer;
