import React from "react";
import { useLocation } from "react-router-dom";
import "./Job.css";
import MainCover from "../MainCover/MainCover";

const Job = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname === "/croupier-job" && <MainCover />}
      <section className="winn-job pad">
        <h2 className="col-white">Croupier job description</h2>
        <p className="t-20 col-white">
          Let's take a closer look at who a croupier is and what the job
          entails. Croupier is a complex and in-demand profession in the gaming
          world. It is hard to imagine a modern casino without a pleasant,
          well-mannered and knowledgeable croupier. After all, people go to the
          casino not only for the thrills, but also for the company. If you have
          these qualities, it will be much easier for you to start your work.
          This job requires both personal and professional skills.
        </p>
        <p className="t-20 fw-500 col-white">Personal skills include:</p>
        <ul className="winn-list">
          <li className="t-20 col-white">Stress tolerance</li>
          <li className="t-20 col-white">Eloquence</li>
          <li className="t-20 col-white">Patience</li>
          <li className="t-20 col-white">Discipline</li>
          <li className="t-20 col-white">Willingness to develop</li>
          <li className="t-20 col-white">Developed mathematical skills</li>
        </ul>
        <p className="t-20 col-white">
          If you have such a list of personal qualities, then you can safely
          think about learning to be a croupier. By taking courses at the best
          schools in Europe, you will be exposed to a lot of theoretical
          material that will help you to play the game competently and quickly.
          Thanks to the courses, you will acquire professional skills:
        </p>
        <ul className="winn-list">
          <li className="t-20 col-white">Know the rules of the games</li>
          <li className="t-20 col-white">Quickly calculate customer bets</li>
          <li className="t-20 col-white">Learn card combinations</li>
          <li className="t-20 col-white">Learn the rules of the game</li>
        </ul>
      </section>
    </>
  );
};

export default Job;
