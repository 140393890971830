import React, { useState } from "react";
import Destinations from "../Destinations/Destinations";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import btn from "../../images/arrow-down.svg";
import "../UK/Country.css";
import Grid from "../Grid/Grid";
import img1 from "../../images/italy-1.webp";
import img2 from "../../images/italy-2.webp";
import img3 from "../../images/italy-3.webp";
import img4 from "../../images/italy-4.webp";
import img5 from "../../images/italy-5.webp";

const Italy = () => {
  const [isDown, setIsDown] = useState(false);
  return (
    <>
      <section className="winn-main__cover winn-country__cover">
        <div className="winn-main__drop-down">
          <ul className="winn-main__drop-list">
            <li className="winn-main__drop-item">
              <Link to="/italy">Italy</Link>
            </li>
            {isDown && (
              <>
                <li className="winn-main__drop-item">
                  <Link to="/france">France</Link>
                </li>
                <li className="winn-main__drop-item">
                  <Link to="/portugal">Portugal</Link>
                </li>
                <li className="winn-main__drop-item">
                  <Link to="/united-kingdom">United Kingdom</Link>
                </li>
                <li className="winn-main__drop-item">
                  <Link to="/spain">Spain</Link>
                </li>
              </>
            )}
          </ul>
          <div className="winn-main__drop-btn">
            <img
              src={btn}
              alt="drop down button"
              title="drop down button"
              onClick={() => setIsDown(!isDown)}
              className={isDown ? "rotate" : ""}
            />
          </div>
        </div>
        <Header />
        <div className="winn-main__body">
          <h1 className="winn-main__header winn-country__header col-white">
            Top 5 Best Casino Dealer Schools in Italy
          </h1>
        </div>
      </section>
      <Destinations />
      <section className="winn-casinos pad">
        <h2 className="winn-casinos__header">Casinos</h2>
        <div className="winn-casinos__body">
          <ul className="winn-casinos__list">
            <li className="winn-casinos__item">
              <p className="t-35">Goldbet Casino Dealer School</p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span> Includes
                in-depth modules on game mechanics and customer relations in
                both land-based and online settings.
              </p>
            </li>
            <li className="winn-casinos__item">
              <p className="t-35">Sanremo Casino Training School</p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span> Comprehensive
                training in Roulette, Blackjack, and Poker games. Offers modules
                on casino management.
              </p>
            </li>
            <li className="winn-casinos__item">
              <p className="t-35">Casino Campione d'Italia Academy</p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span> Advanced
                croupier courses including Texas Hold'em and multi-game
                proficiency.
              </p>
            </li>
            <li className="winn-casinos__item">
              <p className="t-35">Casino di Venezia Academy</p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span> Dealer
                training with a focus on Roulette, Baccarat, and Texas Hold'em
                Poker.
              </p>
            </li>

            <li className="winn-casinos__item">
              <p className="t-35">StarVegas Casino Academy</p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span> Offers a full
                croupier curriculum, focusing on table management and
                mathematical skills.
              </p>
            </li>
          </ul>
          <Grid
            name={"Casino di Venezia"}
            img1={img1}
            img2={img2}
            img3={img3}
            img4={img4}
            text="Casino di Venezia, the oldest casino in the world, offers training for croupiers in its training center. Students learn the rules of roulette, blackjack and other games popular in Europe."
            img5={img5}
          />
        </div>
      </section>
    </>
  );
};

export default Italy;
