import React, { useState } from "react";
import Destinations from "../Destinations/Destinations";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import btn from "../../images/arrow-down.svg";
import "./Country.css";
import Grid from "../Grid/Grid";
import img1 from "../../images/uk-1.webp";
import img2 from "../../images/uk-2.webp";
import img3 from "../../images/uk-3.webp";
import img4 from "../../images/uk-4.webp";
import img5 from "../../images/uk-5.webp";

const Britain = () => {
  const [isDown, setIsDown] = useState(false);
  return (
    <>
      <section className="winn-main__cover winn-country__cover">
        <div className="winn-main__drop-down">
          <ul className="winn-main__drop-list">
            <li className="winn-main__drop-item">
              <Link to="/united-kingdom">United Kingdom</Link>
            </li>
            {isDown && (
              <>
                <li className="winn-main__drop-item">
                  <Link to="/portugal">Portugal</Link>
                </li>
                <li className="winn-main__drop-item">
                  <Link to="/italy">Italy</Link>
                </li>
                <li className="winn-main__drop-item">
                  <Link to="/france">France</Link>
                </li>
                <li className="winn-main__drop-item">
                  <Link to="/spain">Spain</Link>
                </li>
              </>
            )}
          </ul>
          <div className="winn-main__drop-btn">
            <img
              src={btn}
              alt="drop down button"
              title="drop down button"
              onClick={() => setIsDown(!isDown)}
              className={isDown ? "rotate" : ""}
            />
          </div>
        </div>
        <Header />
        <div className="winn-main__body">
          <h1 className="winn-main__header winn-country__header col-white">
            Top 5 Best Casino Dealer Schools in United Kingdom
          </h1>
        </div>
      </section>
      <Destinations />
      <section className="winn-casinos pad">
        <h2 className="winn-casinos__header">Casinos</h2>
        <div className="winn-casinos__body">
          <ul className="winn-casinos__list">
            <li className="winn-casinos__item">
              <p className="t-35">Ace Academy</p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span> Casino
                Croupier training (8 weeks). Includes American Roulette,
                Blackjack, 3 Card Poker, and Baccarat. Focuses on game
                procedures, customer service, and legal frameworks​.
              </p>
            </li>
            <li className="winn-casinos__item">
              <p className="t-35">Tottenham Casino Training School</p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span> Training
                includes all casino table games, practical simulations, and
                customer interaction techniques.
              </p>
            </li>
            <li className="winn-casinos__item">
              <p className="t-35">London Academy of Gaming</p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span> Extensive
                dealer training including American Roulette, Blackjack, Casino
                Stud Poker, Punto Banco, and Dice.
              </p>
            </li>
            <li className="winn-casinos__item">
              <p className="t-35">The Gaming Academy</p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span> Croupier
                training for various games, emphasizing practical skills and
                live dealing.
              </p>
            </li>
            <li className="winn-casinos__item">
              <p className="t-35">Grosvenor Casinos Dealer School</p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span> Covers all
                aspects of casino games, focusing on American Roulette,
                Blackjack, and customer interactions.
              </p>
            </li>
          </ul>
          <Grid
            name={"The Hippodrome Casino"}
            img1={img1}
            img2={img2}
            img3={img3}
            img4={img4}
            text="The Hippodrome Casino in London offers specialized courses for croupiers, including training in roulette, poker and other popular games in the UK."
            img5={img5}
          />
        </div>
      </section>
    </>
  );
};

export default Britain;
