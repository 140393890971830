import React from "react";
import "./Grid.css";

const Grid = ({ name, img1, img2, img3, img4, text, img5 }) => {
  return (
    <ul className="winn-grid">
      <li className="winn-grid__item">
        <p className="t-20">{name}</p>
        <img src={img1} alt="name" title={name} />
      </li>
      <li className="winn-grid__item">
        <p className="t-20">Casino games room</p>
        <img src={img2} alt="Casino games room one" title="Casino games room one" />
      </li>
      <li className="winn-grid__item">
        <p className="t-20">Casino games room</p>
        <img src={img3} alt="Casino games room two" title="Casino games room two" />
      </li>
      <li className="winn-grid__item">
        <p className="t-20">Learning process</p>
        <img src={img4} alt="Learning process" title="Learning process" />
      </li>
      <li className="winn-grid__item text">
        <p className="col-white">{text}</p>
      </li>
      <li className="winn-grid__item">
        <p className="t-20">Slot</p>
        <img src={img5} alt="Slot" title="Slot" />
      </li>
    </ul>
  );
};

export default Grid;
