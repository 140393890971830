import "./Top.css";
import burgerMenu from "../../images/burger-menu.svg";
import closeBtn from "../../images/close-btn.svg";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

function Top() {
  const [date, setDate] = useState("");
  const [isActive, setIsActive] = useState(false);
  function handleClick() {
    setIsActive(false);
  }

  function updateDateTime() {
    const todayDate = new Date();

    // Locale-specific date and time
    const formattedDateTime = todayDate.toLocaleString("en-AU", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });

    setDate(formattedDateTime);
  }

  useEffect(() => {
    updateDateTime();

    const intervalId = setInterval(updateDateTime, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
    <div className="winn-top__container">
      <div className="burger-menu">
        <div id="burger-menu" className="winn-burger__icon-container">
          <img
            src={burgerMenu}
            alt="burger menu icon"
            className="winn-burger__icon"
            onClick={() => setIsActive(true)}
          />
        </div>
        <nav
          className={`burger-menu__nav ${isActive ? "active" : ""}`}
          id="nav"
        >
          <img
            src={closeBtn}
            className="burger__close-btn"
            id="close-icon"
            alt="close menu icon"
            onClick={handleClick}
          />
          <div className="burger-menu__nav-container">
            <Link to="/" className="winn-header__link" onClick={handleClick}>
              Main page
            </Link>
            <Link
              to="/our-services"
              className="winn-header__link"
              onClick={handleClick}
            >
              Our services
            </Link>
            <Link
              to="/training"
              className="winn-header__link"
              onClick={handleClick}
            >
              Learning
            </Link>
            <Link
              to="/croupier-job"
              className="winn-header__link"
              onClick={handleClick}
            >
              Croupier job
            </Link>
            <Link
              to="https://www.casinomeister.com/forums/"
              target="_blank"
              className="winn-header__link"
              onClick={handleClick}
            >
              Forum
            </Link>
            <Link
              to="/casino-show"
              className="winn-header__link"
              onClick={handleClick}
            >
              Casino Show
            </Link>
            <Link
              to="/contact-us"
              className="winn-header__link"
              onClick={handleClick}
            >
              Contacts
            </Link>
          </div>
        </nav>
      </div>
      <p className="winn-date">{date}</p>
      <p className="winn-top__text text-center">
        Top 5 Best Casino Dealer Schools in Europe.
      </p>
    </div>
    <p className="winn-top__mob-text">Top 5 Best Casino Dealer Schools in Europe.</p>
    </>
  );
}

export default Top;
