import React, { useState } from "react";
import Destinations from "../Destinations/Destinations";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import btn from "../../images/arrow-down.svg";
import "../UK/Country.css";
import Grid from "../Grid/Grid";
import img1 from "../../images/portugal-1.webp";
import img2 from "../../images/portugal-2.webp";
import img3 from "../../images/portugal-3.webp";
import img4 from "../../images/portugal-4.webp";
import img5 from "../../images/portugal-5.webp";

const Portugal = () => {
  const [isDown, setIsDown] = useState(false);
  return (
    <>
      <section className="winn-main__cover winn-country__cover">
        <div className="winn-main__drop-down">
          <ul className="winn-main__drop-list">
            <li className="winn-main__drop-item">
              <Link to="/portugal">Portugal</Link>
            </li>
            {isDown && (
              <>
                <li className="winn-main__drop-item">
                  <Link to="/france">France</Link>
                </li>
                <li className="winn-main__drop-item">
                  <Link to="/italy">Italy</Link>
                </li>
                <li className="winn-main__drop-item">
                  <Link to="/united-kingdom">United Kingdom</Link>
                </li>
                <li className="winn-main__drop-item">
                  <Link to="/spain">Spain</Link>
                </li>
              </>
            )}
          </ul>
          <div className="winn-main__drop-btn">
            <img
              src={btn}
              alt="drop down button"
              title="drop down button"
              onClick={() => setIsDown(!isDown)}
              className={isDown ? "rotate" : ""}
            />
          </div>
        </div>
        <Header />
        <div className="winn-main__body">
          <h1 className="winn-main__header winn-country__header col-white">
            Top 5 Best Casino Dealer Schools in Portugal
          </h1>
        </div>
      </section>
      <Destinations />
      <section className="winn-casinos pad">
        <h2 className="winn-casinos__header">Casinos</h2>
        <div className="winn-casinos__body">
          <ul className="winn-casinos__list">
            <li className="winn-casinos__item">
              <p className="t-35">Pestana Casino Academy</p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span> Focus on game
                dealing and etiquette for international casinos. Includes
                internships at major casinos.
              </p>
            </li>
            <li className="winn-casinos__item">
              <p className="t-35">Casino Lisboa Training Center</p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span> Training in
                American Roulette, Blackjack, Poker, and Punto Banco. Includes
                regulatory and legal training
              </p>
            </li>
            <li className="winn-casinos__item">
              <p className="t-35">Solverde Casino Academy</p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span> Comprehensive
                croupier training including all major table games and the local
                gambling laws
              </p>
            </li>
            <li className="winn-casinos__item">
              <p className="t-35">Casino Figueira Dealer School</p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span> Specializes
                in training for Poker and Blackjack, with additional sessions on
                handling high-pressure environments.
              </p>
            </li>

            <li className="winn-casinos__item">
              <p className="t-35">Estoril Sol Casino Training Academy</p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span> Dealer
                training focusing on Roulette, Blackjack, and Poker. Offers
                in-depth customer service modules.
              </p>
            </li>
          </ul>
          <Grid
            name={"Casino Estoril"}
            img1={img1}
            img2={img2}
            img3={img3}
            img4={img4}
            text="The Hippodrome Casino v Londýně nabízí specializované kurzy pro krupiéry, včetně školení v ruletě, pokeru a dalších populárních her ve Velké Británii."
            img5={img5}
          />
        </div>
      </section>
    </>
  );
};

export default Portugal;
