import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../Header/Header";
import btn from "../../images/arrow-down.svg";
import "./MainCover.css";

const MainCover = () => {
  const [isDown, setIsDown] = useState(false);
  const location = useLocation();
  return (
    <section className="winn-main__cover">
      <div className="winn-main__drop-down">
        <ul className="winn-main__drop-list">
          <li className="winn-main__drop-item">
            <Link to="/france">France</Link>
          </li>
          <li className="winn-main__drop-item">
            <Link to="/portugal">Portugal</Link>
          </li>
          <li className="winn-main__drop-item">
            <Link to="/italy">Italy</Link>
          </li>
          {isDown && (
            <>
              <li className="winn-main__drop-item">
                <Link to="/united-kingdom">United Kingdom</Link>
              </li>
              <li className="winn-main__drop-item">
                <Link to="/spain">Spain</Link>
              </li>
            </>
          )}
        </ul>
        <div className="winn-main__drop-btn">
          <img
            src={btn}
            alt="drop down button"
            title="drop down button"
            onClick={() => setIsDown(!isDown)}
            className={isDown ? "rotate" : ""}
          />
        </div>
      </div>
      <Header />
      {location.pathname === "/contact-us" ? (
        <div className="winn-main__body">
          <h1 className="winn-main__header col-white">Contacts</h1>
          <p className="t-25 col-white">
            <span className="fw-500">Address:</span> 120 Collins Street,
            Melbourne, VIC 3000, Australia
          </p>
          <p className="t-25 col-white">
            <span className="fw-500">Phone:</span> +61 3 9000 1234
          </p>
          <p className="t-25 col-white">
            <span className="fw-500">Email:</span> info@winningaustralia.com
          </p>
        </div>
      ) : (
        <div className="winn-main__body">
          <h1 className="winn-main__header col-white">
            Best list of legal and safe croupier schools with high rankings
          </h1>
          <p className="winn-main__text t-20 col-white">
            Link your life with a promising profession in the gaming world. We
            offer you a unique opportunity to study at the best schools in
            Europe and get good working terms.
          </p>
          <Link to="/contact-us" className="winn-main__link t-25">
            Become a croupier
          </Link>
        </div>
      )}
    </section>
  );
};

export default MainCover;
