import React, { useEffect, useState } from "react";
import "./Popup.css";

const Popup = () => {
  const [isPopupAccepted, setIsPopupAccepted] = useState(false);

  useEffect(() => {
    const popup = localStorage.getItem("popup");
    if (popup) {
      setIsPopupAccepted(true);
    }
  }, []);

  function addPopupCookie() {
    localStorage.setItem("popup", "1");
    setIsPopupAccepted(true);
  }

  return (
    !isPopupAccepted && (
      <div className="winn-popup-cookie">
        <form className="winn-popup__form winn-popup__cookie-form">
          <p className="winn-popup__cookie-header">Cookies policy</p>
          <p className="winn-popup__cookie-text">
            We use cookies to improve the functionality of our website and to
            provide you with personalized content. By continuing to use this
            site, you agree to our use of cookies and our data processing
            policy.
          </p>
          <div className="winn-popup__btn-container">
            <a href="/cookies" className="winn-popup__btn-cookie">
              Information
            </a>
            <p
              className="winn-popup__btn-cookie winn-popup__btn-agree"
              onClick={addPopupCookie}
            >
              Agree
            </p>
          </div>
        </form>
      </div>
    )
  );
};

export default Popup;
