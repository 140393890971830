import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import rev1 from "../../images/Michela.webp";
import rev2 from "../../images/Andrea.webp";
import rev3 from "../../images/Julia.webp";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import 'swiper/css/navigation';

import "./Reviews.css";

// import required modules
import { Navigation, Pagination } from "swiper/modules";

const Reviews = () => {
  return (
    <section className="winn-reviews">
      <h2 className="winn-reviews__header text-center">Reviews</h2>
      <Swiper
      modules={[Navigation, Pagination]}
      spaceBetween={30}
      slidesPerView={1} // Показываем 1.5 слайда для эффекта частично видимых боковых слайдов
      navigation // Добавляем кнопки навигации
      pagination={{ clickable: true }} // Добавляем пагинацию
      className="winn-swiper"
    >
        <SwiperSlide className="winn-reviews__slide">
          <p className="t-15 col-white">
            Despite having a job, a house, animals and a boyfriend, I managed to
            make this dream come true.
          </p>
          <div className="winn-reviews__img-container">
            <img
              src={rev1}
              alt="Michela"
              title="Michela"
              width="70"
              height="70"
            />
            <p className="t-15 col-white">
              <span className="fw-500">Michela</span>
              <br></br>Madrid, Spain
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide className="winn-reviews__slide">
          <p className="t-15 col-white">
            Today I did a test in London and I was accepted!!!! I played for an
            hour and a half without any mistakes. Thanks to all the teachers,
            it's all thanks to you!
          </p>
          <div className="winn-reviews__img-container">
            <img
              src={rev2}
              alt="Andrea"
              title="Andrea"
              width="70"
              height="70"
            />
            <p className="t-15 col-white">
              <span className="fw-500">Andrea</span>
              <br></br>London, United Kingdom
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide className="winn-reviews__slide">
          <p className="t-15 col-white">
            I did an intensive course and then got a job in a casino in Sydney.
            I am very grateful for my new opportunity!!!!
          </p>
          <div className="winn-reviews__img-container">
            <img src={rev3} alt="Julia" title="Julia" width="70" height="70" />
            <p className="t-15 col-white">
              <span className="fw-500">Julia</span>
              <br></br>Sydney, Australia
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default Reviews;
