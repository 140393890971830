import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";

const Header = () => {
  return (
    <header className="winn-header__link-container">
        <Link to="/" className="winn-header__link">
          Main page
        </Link>
        <Link to="/our-services" className="winn-header__link">
          Our services
        </Link>
        <Link to="/training" className="winn-header__link">
          Learning
        </Link>
        <Link to="/croupier-job" className="winn-header__link">
          Croupier job
        </Link>
        <Link
          to="https://www.casinomeister.com/forums/"
          target="_blank"
          className="winn-header__link"
        >
          Forum
        </Link>
        <Link to="/casino-show" className="winn-header__link">
          Casino Show
        </Link>
        <Link to="/contact-us" className="winn-header__link">
          Contacts
        </Link>
    </header>
  );
};

export default Header;
