import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper/modules";
import "./Destinations.css";

const Destinations = () => {
  return (
    <div className="winn-destinations">
      <Swiper
        slidesPerView={2}
        loop={true}
        navigation={true}
        modules={[Navigation]}
        className="winn-destinations__swiper"
      >
        <SwiperSlide className="winn-destinations__swiper-slide">
          <Link to="/france" className="winn-destinations__link">
            France
          </Link>
        </SwiperSlide>
        <SwiperSlide className="winn-destinations__swiper-slide">
          <Link to="/portugal" className="winn-destinations__link">
          Portugal
          </Link>
        </SwiperSlide>
        <SwiperSlide className="winn-destinations__swiper-slide">
          <Link to="/italy" className="winn-destinations__link">
          Italy
          </Link>
        </SwiperSlide>
        <SwiperSlide className="winn-destinations__swiper-slide">
          <Link to="/united-kingdom" className="winn-destinations__link text-center">
          United Kingdom
          </Link>
        </SwiperSlide>
        <SwiperSlide className="winn-destinations__swiper-slide">
          <Link to="/spain" className="winn-destinations__link">
          Spain
          </Link>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Destinations;
