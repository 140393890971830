import React, { useState } from "react";
import Destinations from "../Destinations/Destinations";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import btn from "../../images/arrow-down.svg";
import "../UK/Country.css";
import Grid from "../Grid/Grid";
import img1 from "../../images/france-1.webp";
import img2 from "../../images/france-2.webp";
import img3 from "../../images/france-3.webp";
import img4 from "../../images/france-4.webp";
import img5 from "../../images/france-5.webp";

const France = () => {
  const [isDown, setIsDown] = useState(false);
  return (
    <>
      <section className="winn-main__cover winn-country__cover">
        <div className="winn-main__drop-down">
          <ul className="winn-main__drop-list">
            <li className="winn-main__drop-item">
              <Link to="/france">France</Link>
            </li>
            {isDown && (
              <>
                <li className="winn-main__drop-item">
                  <Link to="/portugal">Portugal</Link>
                </li>
                <li className="winn-main__drop-item">
                  <Link to="/italy">Italy</Link>
                </li>
                <li className="winn-main__drop-item">
                  <Link to="/united-kingdom">United Kingdom</Link>
                </li>
                <li className="winn-main__drop-item">
                  <Link to="/spain">Spain</Link>
                </li>
              </>
            )}
          </ul>
          <div className="winn-main__drop-btn">
            <img
              src={btn}
              alt="drop down button"
              title="drop down button"
              onClick={() => setIsDown(!isDown)}
              className={isDown ? "rotate" : ""}
            />
          </div>
        </div>
        <Header />
        <div className="winn-main__body">
          <h1 className="winn-main__header winn-country__header col-white">
            Top 5 Best Casino Dealer Schools in France
          </h1>
        </div>
      </section>
      <Destinations />
      <section className="winn-casinos pad">
        <h2 className="winn-casinos__header">Casinos</h2>
        <div className="winn-casinos__body">
          <ul className="winn-casinos__list">
            <li className="winn-casinos__item">
              <p className="t-35">Casino Barrière Dealer School</p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span> Complete
                training in Roulette, Blackjack, and Poker with emphasis on
                customer service.
              </p>
            </li>
            <li className="winn-casinos__item">
              <p className="t-35">Monte Carlo Casino School</p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span> Croupier
                training for high-stakes games, including French Roulette and
                Baccarat.
              </p>
            </li>
            <li className="winn-casinos__item">
              <p className="t-35">Casino Partouche Training Academy</p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span> Specializes
                in croupier skills for American Roulette, Poker, and advanced
                customer interaction.
              </p>
            </li>
            <li className="winn-casinos__item">
              <p className="t-35">Casino de Deauville Academy</p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span>Courses:
                Focuses on Roulette, Blackjack, and VIP customer service.
              </p>
            </li>

            <li className="winn-casinos__item">
              <p className="t-35">Casino Aix-en-Provence Dealer School</p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span> Offers
                practical training in various table games with internships in
                live casinos.
              </p>
            </li>
          </ul>
          <Grid
            name={"Casino Barrière de Deauville"}
            img1={img1}
            img2={img2}
            img3={img3}
            img4={img4}
            text="Casino Barrière de Deauville offers training for croupiers with a focus on French roulette and baccarat. The Program includes practical exercises with professional instructors."
            img5={img5}
          />
        </div>
      </section>
    </>
  );
};

export default France;
