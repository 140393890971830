import React, { useState } from "react";
import "./Form.css";
import axios from "axios";
import sendBtn from "../../images/form-arrow.svg";
import closeBtn from "../../images/close-btn.svg";

const Form = () => {
  const [isSend, setIsSend] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post("mail.php", formData)
      .then((response) => {
        console.log("Message send");
        setIsSend(true);
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      })
      .catch((error) => {
        console.log("Error sending message");
      });
  };

  return (
    <section className="winn-form pad">
      {isSend && (
        <div className="winn-popup">
          <div className="winn-popup-form__body">
            <img
              src={closeBtn}
              alt="close button"
              title="close button"
              className="winn-popup-form__close"
              onClick={() => setIsSend(false)}
            />
            <p className="t-25 text-center">
              Thank you for your request, our manager will contact you shortly
            </p>
          </div>
        </div>
      )}
      <h2 className="winn-form__header col-white">Become a croupier</h2>
      <p className="t-25 winn-form__text  col-white">
        Please provide your name, contact details and a short message about your
        interest in working as a croupier. We'll get back to you soon!
      </p>

      <form onSubmit={handleSubmit} className="winn-form__form">
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Name and Surname"
          className="winn-form__input"
          required
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Mail"
          className="winn-form__input"
          required
        />
        <input
          type="phone"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Phone"
          className="winn-form__input"
          required
        />
        <input
          name="message"
          type="text"
          value={formData.message}
          onChange={handleChange}
          placeholder="Message"
          className="winn-form__input"
        />
        <button type="submit" className="winn-form__btn t-24">
          <img
            src={sendBtn}
            alt="send button"
            title="send button"
            className="winn-btn"
          />
        </button>
      </form>
      <p className="t-25 winn-form__text col-white">
        Our specialists will contact you with further information. We will
        answer all your questions and help you start a successful career in the
        casino.
      </p>
    </section>
  );
};

export default Form;
