import React from "react";
import { useLocation } from "react-router-dom";
import "./Show.css";
import MainCover from "../MainCover/MainCover";

const Show = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname === "/casino-show" && <MainCover />}
      <section className="winn-show pad">
        <h2 className="col-white">Casino Show</h2>
        <p className="t-20 col-white">
          For those who want to immerse themselves in the colourful and
          exhilarating atmosphere of the casino, we offer a unique opportunity
          to organise an off-site event that meets world-class standards. We can
          accommodate any number of your guests and roll everyone on a wave of
          excitement and success. To your attention show programme that can be
          decorated under any style, whether it is a Casino Monaco or Las Vegas.
          The only limit is your imagination and we are your performers. We
          offer you the following list of services:
        </p>
        <ul className="winn-list">
          <li className="t-20 col-white">
            <span className="fw-500">Decoration:</span> We offer you the
            services of your own personal designer to help you design your
            decor.
          </li>
          <li className="t-20 col-white">
            <span className="fw-500">Games room:</span> We provide gaming
            equipment, card tables and professional staff to run your evening.
          </li>
          <li className="t-20 col-white">
            <span className="fw-500">Entertainment:</span> We will provide a
            professional host at the event to entertain your guests.
          </li>
          <li className="t-20 col-white">
            <span className="fw-500">Catering:</span> There are several options
            for decorating your buffet. You can order and receive ready-made
            starters or hire a team of chefs to prepare the best gastronomic
            dishes for you.
          </li>
          <li className="t-20 col-white">
            <span className="fw-500">Try your hand as a croupier:</span> For
            your guests, we will provide an instructor who will help anyone who
            wants to feel like a croupier and play the game.
          </li>
          <li className="t-20 col-white">
            <span className="fw-500">Accompaniment:</span> We provide full
            support for your evening and answer any questions that may arise.
            All you have to do is relax and have fun!
          </li>
        </ul>
      </section>
    </>
  );
};

export default Show;
