import React from "react";
import Form from "../Form/Form";
import MainCover from "../MainCover/MainCover";

const ContactUs = () => {
  return (
    <>
      <MainCover />
      <Form />
    </>
  );
};

export default ContactUs;
