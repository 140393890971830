import React from "react";
import "./Main.css";
import MainCover from "../MainCover/MainCover";
import Destinations from "../Destinations/Destinations";
import Training from "../Training/Training";
import Services from "../Services/Services";
import Job from "../Job/Job";
import Show from "../Show/Show";
import Reviews from "../Reviews/Reviews";
import Form from "../Form/Form";


// import MainPopup from "../Popup/MainPopup";


const Main = () => {

  return (
    <main className="winn-main">
      {/* <MainPopup/> */}
      <MainCover/>
      <Destinations/>
      <Services />
      <Training />
      <Job/>
      <Show/>
      <Reviews />
      <Form />
    </main>
  );
};

export default Main;
