import { Route, Routes } from "react-router-dom";
import "../App/App.css";
import Top from "../Top/Top";
import Main from "../Main/Main";
import Training from "../Training/Training";
import Services from "../Services/Services";
import Job from "../Job/Job";
import Show from "../Show/Show";
import ContactUs from "../ContactUs/ContactUs";
import Footer from "../Footer/Footer";
import Popup from "../Popup/Popup";
import Cookies from "../Cookies/Cookies";
import Privacy from "../Privacy/Privacy";
import ScrollToTop from "../Scroll/Scroll";
import Britain from "../UK/UK";
import France from "../France/France";
import Italy from "../Italy/Italy";
import Portugal from "../Portugal/Portugal";
import Spain from "../Spain/Spain";

function App() {
  return (
    <div className="winn-page">
      <ScrollToTop />
      <Top />
      <Popup />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/training" element={<Training />} />
        <Route path="/our-services" element={<Services />} />
        <Route path="/croupier-job" element={<Job />} />
        <Route path="/casino-show" element={<Show />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/france" element={<France />} />
        <Route path="/united-kingdom" element={<Britain />} />
        <Route path="/italy" element={<Italy />} />
        <Route path="/portugal" element={<Portugal />} />
        <Route path="/spain" element={<Spain />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
