import React from "react";
import { useLocation } from "react-router-dom";
import "./Services.css";
import MainCover from "../MainCover/MainCover";

const Services = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname === "/our-services" && <MainCover />}
      <section className="winn-services pad">
        <h2 className="winn-services__header">Our services</h2>
        <p className="t-20">
          Hello dear reader, today we are going to get to know each other
          better. If you are looking for training for an in-demand profession
          with a range of benefits, then you have come to the right place. Our
          team specialises in helping you organise training at the best croupier
          schools in Europe. We will work with you to take all the hassle out of
          the process. Our list of services includes:
        </p>
        <ul className="winn-list">
          <li className="t-20">
            <span className="fw-500">Individual interview:</span> One of the
            most important stages of our collaboration. During the interview we
            will ask you key questions that will allow us to create a plan for
            your development, and you will also be able to ask questions that
            concern you.
          </li>
          <li className="t-20">
            <span className="fw-500">Choosing a school:</span> Once you have a
            plan, we suggest the most suitable schools with the right programmes
            for you.
          </li>
          <li className="t-20">
            <span className="fw-500">Enrolment:</span> Schools have offline and
            online classes. Different types of study require different lists of
            documents, which we will help you to compile.
          </li>
          <li className="t-20">
            <span className="fw-500">Transfer:</span> The best croupier schools
            are located in the educational centres of Europe. We can help you
            get to the school and assist with accommodation.
          </li>
          <li className="t-20">
            <span className="fw-500">Employment:</span> After graduating with an
            international diploma, we will help all graduates to find their
            first job to put their theoretical knowledge into practice.
          </li>
          <li className="t-20">
            <span className="fw-500">Support:</span> We support all students
            throughout their studies and help you with exams.
          </li>
          <li className="t-20">
            <span className="fw-500">Casino Show:</span> A unique show programme
            you can book to immerse yourself in the world of gaming and
            entertainment. See separate article for more details.
          </li>
        </ul>
      </section>
    </>
  );
};

export default Services;
