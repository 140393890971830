import React, { useState } from "react";
import Destinations from "../Destinations/Destinations";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import btn from "../../images/arrow-down.svg";
import "../UK/Country.css";
import Grid from "../Grid/Grid";
import img1 from "../../images/spain-1.webp";
import img2 from "../../images/spain-2.webp";
import img3 from "../../images/spain-3.webp";
import img4 from "../../images/spain-4.webp";
import img5 from "../../images/spain-5.webp";

const Spain = () => {
  const [isDown, setIsDown] = useState(false);
  return (
    <>
      <section className="winn-main__cover winn-country__cover">
        <div className="winn-main__drop-down">
          <ul className="winn-main__drop-list">
            <li className="winn-main__drop-item">
              <Link to="/spain">Spain</Link>
            </li>
            {isDown && (
              <>
                <li className="winn-main__drop-item">
                  <Link to="/france">France</Link>
                </li>
                <li className="winn-main__drop-item">
                  <Link to="/italy">Italy</Link>
                </li>
                <li className="winn-main__drop-item">
                  <Link to="/united-kingdom">United Kingdom</Link>
                </li>
                <li className="winn-main__drop-item">
                  <Link to="/portugal">Portugal</Link>
                </li>
              </>
            )}
          </ul>
          <div className="winn-main__drop-btn">
            <img
              src={btn}
              alt="drop down button"
              title="drop down button"
              onClick={() => setIsDown(!isDown)}
              className={isDown ? "rotate" : ""}
            />
          </div>
        </div>
        <Header />
        <div className="winn-main__body">
          <h1 className="winn-main__header winn-country__header col-white">
            Top 5 Best Casino Dealer Schools in Spain
          </h1>
        </div>
      </section>
      <Destinations />
      <section className="winn-casinos pad">
        <h2 className="winn-casinos__header">Casinos</h2>
        <div className="winn-casinos__body">
          <ul className="winn-casinos__list">
            <li className="winn-casinos__item">
              <p className="t-35">Casino Torrequebrada Academy</p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span> Offers
                training in Blackjack, Poker, and Baccarat. Also provides
                classes in casino law.
              </p>
            </li>
            <li className="winn-casinos__item">
              <p className="t-35">Casino Madrid Academy</p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span> Croupier
                training in Roulette, Blackjack, and Poker. Includes modules on
                mental math and dexterity.
              </p>
            </li>
            <li className="winn-casinos__item">
              <p className="t-35">Casino Marbella Dealer School</p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span> Focuses on
                American Roulette and Poker, with practical hands-on training.
              </p>
            </li>
            <li className="winn-casinos__item">
              <p className="t-35">Casino Barcelona Dealer School </p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span> Training in
                Poker, Blackjack, and Roulette, with a focus on casino
                regulations and customer service.
              </p>
            </li>

            <li className="winn-casinos__item">
              <p className="t-35">Gran Casino Costa Brava Academy</p>
              <p className="t-20">
                <span className="col-green fw-500">Courses:</span> Provides a
                comprehensive dealer course for Roulette, Blackjack, and
                advanced Poker strategies.
              </p>
            </li>
          </ul>
          <Grid
            name={"Casino Barcelona "}
            img1={img1}
            img2={img2}
            img3={img3}
            img4={img4}
            text="Casino Barcelona offers croupier training courses, including lessons in roulette, blackjack, and poker. The training center provides the opportunity to gain skills and experience for working."
            img5={img5}
          />
        </div>
      </section>
    </>
  );
};

export default Spain;
