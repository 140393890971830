import React from "react";
import { useLocation } from "react-router-dom";
import "./Training.css";
import MainCover from "../MainCover/MainCover";

const Training = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname === "/training" && <MainCover />}
      <section className="winn-training pad">
        <h2 className="winn-training__header fw-500">Learning</h2>
        <p className="t-20 fw-500">
          To start working, you need an international diploma, which can only be
          obtained from the best dealer schools in Europe. The profession
          involves learning complex theoretical material that you will need to
          refine in practice. In return, you will receive a number of benefits,
          including:
        </p>
        <ul className="winn-list">
          <li className="t-20 fw-500">High salary</li>
          <li className="t-20 fw-500">Generous bonus scheme and tips</li>
          <li className="t-20 fw-500">Flexible working hours</li>
          <li className="t-20 fw-500">In-demand speciality</li>
          <li className="t-20 fw-500">Career progression</li>
          <li className="t-20 fw-500">
            Development of professional and personal skills
          </li>
        </ul>
      </section>
    </>
  );
};

export default Training;
